/********** Template CSS **********/
:root {
  --primary: #d81324;
  --secondary: #0b2154;
  --light: #f2f2f2;
  --dark: #111111;
}

.fw-medium {
  font-weight: 600 !important;
}

.back-to-top {
  position: fixed;
  display: none;
  right: 45px;
  bottom: 45px;
  z-index: 99;
}

/*** Spinner ***/
#spinner {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s ease-out, visibility 0s linear 0.5s;
  z-index: 99999;
}

#spinner.show {
  transition: opacity 0.5s ease-out, visibility 0s linear 0s;
  visibility: visible;
  opacity: 1;
}

.logo {
  width: 66px;
  height: auto;
}

/*** Button ***/
.btn {
  font-weight: 500;
  text-transform: uppercase;
  transition: 0.5s;
}

.btn.btn-primary,
.btn.btn-secondary {
  color: #ffffff;
}

.btn-square {
  width: 38px;
  height: 38px;
}

.btn-sm-square {
  width: 32px;
  height: 32px;
}

.btn-lg-square {
  width: 48px;
  height: 48px;
}

.btn-square,
.btn-sm-square,
.btn-lg-square {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: normal;
  border-radius: 2px;
}

/*** Navbar ***/
.navbar .dropdown-toggle::after {
  border: none;
  content: "\f107";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  vertical-align: middle;
  margin-left: 8px;
}

.navbar-light .navbar-nav .nav-link {
  margin-right: 30px;
  padding: 25px 0;
  color: #ffffff;
  font-size: 15px;
  text-transform: uppercase;
  outline: none;
}

.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link.active {
  color: var(--primary) !important;
}

@media (max-width: 991.98px) {
  .navbar-light .navbar-nav .nav-link {
    margin-right: 0;
    padding: 10px 0;
  }

  .navbar-light .navbar-nav {
    border-top: 1px solid #eeeeee;
  }
}

.navbar-light .navbar-brand,
.navbar-light a.btn {
  height: 75px;
}

.navbar-light .navbar-nav .nav-link {
  color: var(--dark);
  font-weight: 500;
}

.navbar-light.sticky-top {
  top: -100px;
  transition: 0.5s;
}

@media (min-width: 992px) {
  .navbar .nav-item .dropdown-menu {
    display: block;
    border: none;
    margin-top: 0;
    top: 150%;
    opacity: 0;
    visibility: hidden;
    transition: 0.5s;
  }

  .navbar .nav-item:hover .dropdown-menu {
    top: 100%;
    visibility: visible;
    transition: 0.5s;
    opacity: 1;
  }
}

/*** Header ***/
.carousel-caption {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: 1;
}

.carousel-control-prev,
.carousel-control-next {
  width: 10%;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  width: 3rem;
  height: 3rem;
}

@media (max-width: 768px) {
  #header-carousel .carousel-item {
    position: relative;
    min-height: 450px;
  }

  #header-carousel .carousel-item img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.page-header {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.page-header-inner {
  background: rgba(0, 0, 0, 0.7);
}

.breadcrumb-item + .breadcrumb-item::before {
  color: var(--light);
}

/*** Facts ***/
.fact {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

/*** Service ***/
.service .nav .nav-link {
  background: var(--light);
  transition: 0.5s;
}

.service .nav .nav-link.active {
  background: var(--primary);
}

.service .nav .nav-link.active h4 {
  color: #ffffff !important;
}

/*** Booking ***/

.bootstrap-datetimepicker-widget.bottom {
  top: auto !important;
}

.bootstrap-datetimepicker-widget .table * {
  border-bottom-width: 0px;
}

.bootstrap-datetimepicker-widget .table th {
  font-weight: 500;
}

.bootstrap-datetimepicker-widget.dropdown-menu {
  padding: 10px;
  border-radius: 2px;
}

.bootstrap-datetimepicker-widget table td.active,
.bootstrap-datetimepicker-widget table td.active:hover {
  background: var(--primary);
}

.bootstrap-datetimepicker-widget table td.today::before {
  border-bottom-color: var(--primary);
}

/*** Team ***/
.team-item .team-overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--primary);
  transform: scale(0);
  transition: 0.5s;
}

.team-item:hover .team-overlay {
  transform: scale(1);
}

.team-item .team-overlay .btn {
  color: var(--primary);
  background: #ffffff;
}

.team-item .team-overlay .btn:hover {
  color: #ffffff;
  background: var(--secondary);
}

/*** Testimonial ***/
.testimonial-carousel .owl-item .testimonial-text,
.testimonial-carousel .owl-item.center .testimonial-text * {
  transition: 0.5s;
}

.testimonial-carousel .owl-item.center .testimonial-text {
  background: var(--primary) !important;
}

.testimonial-carousel .owl-item.center .testimonial-text * {
  color: #ffffff !important;
}

.testimonial-carousel .owl-dots {
  margin-top: 24px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.testimonial-carousel .owl-dot {
  position: relative;
  display: inline-block;
  margin: 0 5px;
  width: 15px;
  height: 15px;
  border: 1px solid #cccccc;
  transition: 0.5s;
}

.testimonial-carousel .owl-dot.active {
  background: var(--primary);
  border-color: var(--primary);
}

/*** Footer ***/
.footer {
  background-size: cover;
}

.footer .btn.btn-social {
  margin-right: 5px;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--light);
  border: 1px solid #ffffff;
  border-radius: 35px;
  transition: 0.3s;
}

.footer .btn.btn-social:hover {
  color: var(--primary);
}

.footer .btn.btn-link {
  display: block;
  margin-bottom: 5px;
  padding: 0;
  text-align: left;
  color: #ffffff;
  font-size: 15px;
  font-weight: normal;
  text-transform: capitalize;
  transition: 0.3s;
}

.footer .btn.btn-link::before {
  position: relative;
  content: "\f105";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  margin-right: 10px;
}

.footer .btn.btn-link:hover {
  letter-spacing: 1px;
  box-shadow: none;
}

.footer .copyright {
  padding: 25px 0;
  font-size: 15px;
  border-top: 1px solid rgba(256, 256, 256, 0.1);
}

.footer .copyright a {
  color: var(--light);
}

.footer .footer-menu a {
  margin-right: 15px;
  padding-right: 15px;
  border-right: 1px solid rgba(255, 255, 255, 0.3);
}

.footer .footer-menu a:last-child {
  margin-right: 0;
  padding-right: 0;
  border-right: none;
}

.item-ndstr {
  padding: 20px 10px;
  border: 1px solid #deecf6;
  width: 30%;
  height: auto;
  margin-right: 30px;
  margin-bottom: 30px;
  transition: 0.2s ease-in;
}

.item-ndstr .title {
  color: #000000;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  text-transform: uppercase;
  text-align: left;
  margin-bottom: 7px;
  display: inline-block;
}

.item-ndstr-img img {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}

@media only screen and (max-width: 1024px) {
  .item-ndstr {
    width: 70%;
    margin-right: 6px;
    float: left;
  }
  .row {
    justify-content: center;
  }
}

.red {
  color: #d2232a;
}

@media only screen and (max-width: 1024px) {
  .box-content-page {
    margin-bottom: 15px;
  }
}

.page-nadstroiki {
  margin-bottom: 0 !important;
}
.box-content-page {
  margin-bottom: 100px;
  margin-top: 45px;
  overflow: hidden;
}
.nadstroiki-img {
  border: 1px solid #e9eff3;
  padding: 16px 17px;
  height: fit-content;
}
.nadstroiki-img img {
  width: 100%;
}

.nadstroiki-text {
  padding: 0 0 0 40px;
}
.nadstroiki-text img {
  float: left;
  border: 1px solid #e9eff3;
  margin: 0 15px 15px 0;
  height: fit-content;
}

.title-box-slider {
  width: 100%;
  padding: 8px 15px;
  background: #f5f8fa;
  margin-bottom: 23px;
  margin-top: 40px;
  color: #4b5964;
  font-family: "Helvetica Neue ", Helvetica, Arial, sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  text-transform: uppercase;
  overflow: hidden;
  display: inline-block;
}

.slider-wath-all {
  margin-bottom: 80px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* Center the items horizontally */
  gap: 22px; /* Adjust the spacing between items */
}

@media only screen and (max-width: 1024px) {
  .slider-wath-all .slid-item {
    width: 180px !important; /* Adjust the width for smaller screens */
  }
  .nadstroiki-text {
    padding: 0 20px;
  }
}

@media only screen and (max-width: 767px) {
  .nadstroiki-text .info {
    margin-top: 30px;
  }
}

.slider-wath-all .slid-item {
  float: left;
  margin-left: 11px;
  margin-right: 11px;
  width: 197px !important;
  min-height: 102px;
  border: 1px solid #e9eff3;
}

.slid-item img {
  width: 100%;
  height: fit-content;
}

.item-ndstr:hover {
  border: 1px solid rgb(229, 23, 23);
  box-shadow: 3px 4px 5px 0px rgba(255, 49, 49, 0.13);
  -webkit-box-shadow: 3px 4px 5px 0px rgba(255, 49, 49, 0.13);
  -moz-box-shadow: 3px 4px 5px 0px rgba(255, 49, 49, 0.13);
}

.nav-item.active .nav-link {
  color: #d81324 !important;
}
.breadcrumb a {
  color: white;
}

.breadcrumb a:hover {
  color: #d81324;
}
.dropdown-menu.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}

.btn-group:hover .dropdown-menu {
  display: block;
}

.email {
  text-wrap: nowrap;
}

.sub-variant-tov {
  list-style: none;
}

@media only screen and (max-width: 768px) {
  .remove-position{
    text-align: center;
  }
}
.table-box{
  width: 99%;
  margin-bottom: 50px;
}
.table-box th{
  background-color: #edf1f3;
  padding: 10px 15px;
  color: #4b5964;
  font-family: "Helvetica Neue ", Helvetica, Arial, sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  text-transform: uppercase;
}
.table-box tr{
  border-bottom: 1px solid #edf1f3;
}
.table-box td:not(.td-left){
  border: 1px solid #edf1f3;
}
.table-box td.td-right-colspan{
  border: none;
}
.table-box td.td-left{
  background: #FAFBFC;
  color: #1c1c1c;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
  border-right: 1px solid #edf1f3;
  width: 40%;
  padding: 0px 0 0 15px;
}
.table-box td.td-right-colspan{
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 30px;
  text-align: center;
  background: #F5F8FA;
}
.table-box td.td-right{
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 30px;
  text-align: center;
}
.td-white-bg{
  background: #FAFBFC;
}